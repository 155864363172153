import { tns } from "tiny-slider";
import Glide from "@glidejs/glide";
let glide = null;

function initFeaturedTalkSlider(id) {
	glide = new Glide("#" + id, {
		type: "carousel",
		startAt: 0,
		bound: false,
		gap: 50,
		perView: 1,
	});

	glide.mount();
}

export function moveGlide(whichWay) {
	glide.go(whichWay);
}

window.initFeaturedTalkSlider = initFeaturedTalkSlider;

function initCategoryCarousel(idCategory) {
	tns({
		container: ".carousel-container-" + idCategory,
		mode: "carousel",
		autoWidth: true,
		controlsContainer: ".controls-" + idCategory,
		controls: true,
		prevButton: ".previous-" + idCategory,
		nextButton: ".next-" + idCategory,
		nav: false,
		loop: true,
		mouseDrag: true,
		gutter: 15,
	});
}

window.initCategoryCarousel = initCategoryCarousel;
